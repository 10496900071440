import io from 'socket.io-client'

const PushSocket = {
  install: (app, { connection, options }) => {
    const socket = io(connection, options)

    app.config.globalProperties.pushSocket = socket

    app.provide('pushSocket', socket)
  }
}

const InteractiveSocket = {
    install: (app, { connection, options }) => {
      const socket = io(connection, options)
  
      app.config.globalProperties.$socketInt = socket
  
      app.provide('socketInt', socket)
    }
  }

export{
    PushSocket,InteractiveSocket
} 